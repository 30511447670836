export class TTVerification {
  conversationRef: String = null;
  status: TTVerificationStatus = TTVerificationStatus.TTVERIFICATION_UNVERIFIED;

  constructor() {
    this.conversationRef = null;
  }

  get verified() {
    return this.status == TTVerificationStatus.TTVERIFICATION_VERIFIED;
  }

  get unverified() {
    return this.status == TTVerificationStatus.TTVERIFICATION_UNVERIFIED;
  }

  get isProcessing() {
    return this.status == TTVerificationStatus.TTVERIFICATION_PROCESSING;
  }

  get isStarted() {
    return this.status == TTVerificationStatus.TTVERIFICATION_STARTED;
  }

  get isClosed() {
    return this.status == TTVerificationStatus.TTVERIFICATION_CLOSED;
  }

  static createTTVerification(data) {
    const verification = new TTVerification();

    if (data) {
      verification.conversationRef = data.conversationRef
        ? data.conversationRef.id
        : null;
      verification.status = data.status;
    } else {
      verification.status = TTVerificationStatus.TTVERIFICATION_UNVERIFIED;
    }

    return verification;
  }
}

export enum TTVerificationStatus {
  TTVERIFICATION_UNVERIFIED = 0,
  TTVERIFICATION_STARTED = 1,
  TTVERIFICATION_PROCESSING = 2,
  TTVERIFICATION_VERIFIED = 3,
  TTVERIFICATION_CLOSED = 4,
}
