export class BlogArticle {
  id: string;
  title: String;
  excerpt: String;
  url: String;
  thubmnailURL: String;
  created: Date;

  constructor() {
    this.id = "";
    this.title = "";
    this.excerpt = "";
    this.url = "";
    this.thubmnailURL = "";
    this.created = new Date();
  }

  static createArticle(id, data) {
    const article = new BlogArticle();

    article.id = id;
    article.title = data.title;
    article.url = data.url;
    article.thubmnailURL = data.thubmnailURL;

    article.excerpt = data.excerpt;

    return article;
  }
}
