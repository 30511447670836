import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminAuthService } from 'src/app/services/adminauth/adminauth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginFormGroup;
  errorMsg = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AdminAuthService
  ) {
    this.loginFormGroup = this.formBuilder.group({
      email: '',
      password: '',
    });
  }

  ngOnInit(): void {}

  onSubmit(loginData) {
    this.authService
      .authenticateUserWithPassword(loginData['email'], loginData['password'])
      .then((data) => {
        this.router.navigate(['dashboard']);
      })
      .catch((reason: any) => {
        this.errorMsg = 'You have entered an invalid email or password.';
      });
  }
}
