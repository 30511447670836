import { Router } from '@angular/router';
import { Observable, of, empty, combineLatest, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { map, flatMap, catchError, tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UserService } from '../../tt-services/user/user.service';
import { User, UserType } from '../../tt-models/user.model';
import { AdminUserService } from '../adminuser/adminuser.service';
import { AdminUser } from '../../models/adminuser.model';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthService {
  currentUserObserver: Observable<AdminUser>;
  currentUser: AdminUser;

  constructor(
    public auth: AngularFireAuth,
    private adminUserService: AdminUserService,
    private router: Router,
    private fbf: AngularFireFunctions
  ) {
    this.currentUserObserver = auth.user
      .pipe(
        map((state) => {
          if (state) {
            return adminUserService
              .getAdminUser(state.uid)
              .pipe(
                catchError((err) => {
                  return throwError(err);
                })
              )
              .pipe(
                map((user) => {
                  if (!user) {
                    throwError('user-does-not-exist');
                  }
                  user.email = state.email;
                  this.currentUser = user;
                  return user;
                })
              );
          } else {
            return of(null);
          }
        })
      )
      .pipe(
        flatMap((user) => {
          return user;
        })
      );

    this.currentUserObserver.subscribe((user) => {
      if (user) {
        // if (!user.verified) {
        //   this.router.navigate(["/verify-mobile"]);
        //   return;
        // }
      }
    });
  }

  async updatePassword(newPassword: string) {
    const user = await this.auth.currentUser;
    return user.updatePassword(newPassword);
  }

  closeAccount() {
    const callable = this.fbf.httpsCallable('closeAccount');
    return callable({});
  }

  async verifyPhoneNumber(
    number,
    recaptchaVerifier: firebase.default.auth.ApplicationVerifier
  ) {
    const user = await this.auth.currentUser;
    try {
      return await user.reauthenticateWithPhoneNumber(
        number,
        recaptchaVerifier
      );
      return;
    } catch (e) {
      console.log(e);
    }
  }

  // getCurrentUserProfile() {
  //   return this.currentUser
  //     .pipe(
  //       map((user) => {
  //         return this.userService.getUser(user.id).pipe(
  //           map((userProfile) => {
  //             return userProfile;
  //           })
  //         );
  //       })
  //     )
  //     .pipe(
  //       flatMap((user) => {
  //         return user;
  //       })
  //     );
  // }

  logout() {
    return this.auth.signOut();
  }

  authenticateUserWithPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async registerUser(email: string, password: string, displayName: string) {
    const userCredential = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );

    userCredential.user.updateProfile({ displayName });

    return { uid: userCredential.user.uid };
  }

  async registerBusiness(email: string, password: string) {
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      await userCredential.user.sendEmailVerification({
        url: 'http://localhost:4200/',
      });

      return { uid: userCredential.user.uid };
    } catch (e) {
      console.log(e);
    }
  }
}
