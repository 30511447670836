export class CandidateUserSettings {
  enablePushNotifications: boolean = false;
  optinForEmailMarketing: boolean = false;

  assign(props: CandidateUserSettingsPartial) {
    for (const key of Object.keys(props)) {
      this[key] = props[key];
    }
  }

  static buildSettings(data: any) {
    let settings = new CandidateUserSettings();

    Object.keys(data).forEach((key) => {
      if (key in settings) {
        let prop = {};
        prop[key] = data[key];
        settings.assign(prop);
      }
    });
    return settings;
  }
}

type CandidateUserSettingsPartial = {
  [P in keyof CandidateUserSettings]?: CandidateUserSettings[P];
};
