import { Industry } from "src/app/tt-models/industry.model";
import { TTVerificationStatus } from "src/app//tt-models/ttverification.model";
import { ConversationType } from "src/app/tt-models/conversation.model";
import { CandidatesService } from "src/app/tt-services/candidates/candidates.service";
import { Subscription } from "src/app/tt-models/subscription.model";
import { Business } from "src/app/tt-models/business.model";
import { User, UserType } from "src/app/tt-models/user.model";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Injectable } from "@angular/core";
import { Observable, from, throwError, of } from "rxjs";
import { map, flatMap, catchError } from "rxjs/operators";
import { Candidate } from "src/app/tt-models/candidate.model";
import { AuthService } from "../auth/auth.service";
import { Address } from "src/app/tt-models/address.model";
import { CandidateUserSettings } from "src/app/tt-models/candidate-settings.model";
import { TTVerification } from "src/app/tt-models/ttverification.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private afs: AngularFirestore,
    private candidateService: CandidatesService
  ) {}

  async getUserObserver(uid: String) {
    return this.afs.collection("users").doc(uid.toString()).valueChanges();
  }

  async startTTVerification(uid: String, candidateRef: String) {
    //Start Conversation
    let conversationsCollection = this.afs.collection("conversations");
    const conversation = await conversationsCollection.add({
      business: null,
      candidate: this.afs.collection("candidates").doc(candidateRef.toString())
        .ref,
      lastMessage: "",
      lastMessageDate: new Date(),
      status: 0,
      type: ConversationType.VERIFICATION_CHAT,
    });

    // const messagesCollection = conversation.collection("messages");
    // await messagesCollection.add({
    //   type: 0,
    //   content: {
    //     text:
    //       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque maxime labore, quisquam nemo laborum tenetur inventore amet quo perspiciatis aliquid eius perferendis sequi ut harum libero laudantium neque. Minus, fuga.",
    //   },
    //   date: new Date(),
    //   sender: "tt",
    // });

    let usersCollection = this.afs.collection("users");

    try {
      await usersCollection.doc(uid.toString()).update({
        ttVerification: {
          status: TTVerificationStatus.TTVERIFICATION_STARTED,
          conversationRef: conversation,
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async updateUserIndustry(uid: string, industry: Industry) {
    try {
      let userDoc = await this.afs
        .collection("users")
        .doc(uid)
        .get()
        .toPromise();
      userDoc.ref.update({ industry: industry });

      return true;
    } catch (e) {
      return false;
    }
  }

  createCandidateAccount(uid: string, data: any) {
    const { account } = data;

    let usersCollection = this.afs.collection("users");
    return from(
      usersCollection.doc(uid).set({
        email: account.email,
        candidate: null,
        firstname: account.firstname,
        lastname: account.lastname,
        mobile: account.mobile,
        industry: account.industry,
        jobType: account.jobType,
        address: {
          postcode: account.postcode,
        },
        settings: {},
        type: "candidate",
      })
    );
  }

  async updateProfileUpdatePromptAck(uid: string) {
    let usersCollection = this.afs.collection("users");
    const user = await usersCollection.doc(uid).get().toPromise();
    if (user.exists) {
      await user.ref.update({ profileUpdatePromptAck: new Date() });
      return true;
    }
    return false;
  }

  onboardCandidate(uid: string, data: any) {
    //Create business first
    let candidatesCollection = this.afs.collection("candidates");

    const { profile, settings } = data;
    return from(candidatesCollection.add(profile)).pipe(
      map((candidateRef) => {
        let usersCollection = this.afs.collection("users");
        return from(
          usersCollection.doc(uid).update({
            candidate: candidateRef,
            settings: settings,
            onboarded: true,
            verified: false,
          })
        );
      })
    );
  }

  async updateBusinessProfile(cid: string, data: any) {
    return await this.afs.collection("businesses").doc(cid).update({
      address: data.address,
      name: data.businessName,
      businessNumber: data.businessNumber,
      companySize: data.companySize,
      telephoneNumber: data.telephoneNumber,
      website: data.website,
    });
  }

  async updateBusinessUser(uid: string, data: any) {
    return await this.afs.collection("users").doc(uid).update({
      address: data.address,
      role: data.role,
    });
  }

  async createBusinessAccount(uid: string, data: any) {
    try {
      //Create business first
      const businessRef = await this.afs.collection("businesses").add({
        address: data.address,
        name: data.businessName,
        businessNumber: data.businessNumber,
        companySize: data.companySize,
        telephoneNumber: data.telephoneNumber,
        website: data.website,
        industry: data.industry,
      });

      return await this.afs
        .collection("users")
        .doc(uid)
        .set({
          industry: data.industry,
          business: businessRef,
          address: data.address,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          type: "business",
          role: data.role,
          subscription: {
            label: "Trial",
            name: "trial",
            remaining_credits: 0,
            total_credits: 0,
            type: 0,
          },
        });
    } catch (e) {
      throw new Error();
    }
  }

  deductCredit(uid: string) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(uid)
      .get()
      .pipe(
        map((docSnap) => {
          if (docSnap.exists) {
            const data = docSnap.data();
            const subscription = data.subscription;
            subscription.remaining_credits -= 1;
            return docSnap.ref.update({
              subscription,
            });
          }
        })
      );
  }

  addUserDeviceToken(userID, token) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          const userData = docSnap.data();
          // const existingTokenArray = userData.deviceTokens || [];
          const existingTokenArray = [];
          existingTokenArray.push(token);

          return from(docSnap.ref.update({ deviceTokens: existingTokenArray }));
        })
      );
  }

  updateUserSetting(userID, settingName, settingValue) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          if (!docSnap.exists) {
            return throwError("user-does-not-exist");
          }

          var settingsUpdate = {};
          settingsUpdate[`settings.${settingName}`] = settingValue;

          docSnap.ref.update(settingsUpdate);
        })
      );
  }

  updateUserAddress(userID, address: Address) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          if (!docSnap.exists) {
            return throwError("user-does-not-exist");
          }

          var settingsUpdate = {};
          settingsUpdate["address"] = address.toJson();

          docSnap.ref.update(settingsUpdate);
        })
      );
  }

  updateUserMobile(userID, mobileNumber: String) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          if (!docSnap.exists) {
            return throwError("user-does-not-exist");
          }

          var settingsUpdate = {};
          settingsUpdate["mobile"] = mobileNumber || "";

          docSnap.ref.update(settingsUpdate);
        })
      );
  }

  getUser(userID) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          if (!docSnap.exists) {
            return throwError("user-does-not-exist");
          }
          const userData = docSnap.data();

          const user = User.createUser(docSnap.id, userData);

          if (userData.type == UserType.CANDIDATE && !userData.onboarded) {
            return from([user]);
          }

          switch (user.type) {
            case UserType.CANDIDATE: {
              if (userData.settings) {
                user.settings = CandidateUserSettings.buildSettings(
                  userData.settings
                );
              }

              const candidateRef = userData.candidate as DocumentReference;
              return this.candidateService.getCandidate(candidateRef.id).pipe(
                map((candidate) => {
                  user.profile = candidate;
                  return user;
                })
              );
            }
            case UserType.BUSINESS: {
              const businessRef = userData.business as DocumentReference;
              return this.getBusiness(businessRef.id).pipe(
                map((business) => {
                  user.profile = business;
                  return user;
                })
              );
            }
          }
        })
      )
      .pipe(
        flatMap((user) => {
          return user;
        })
      );
  }

  watchUser(userID) {
    let usersCollection = this.afs.collection("users");
    return usersCollection
      .doc(userID)
      .snapshotChanges()
      .pipe(
        map((docSnap) => {
          const userData = docSnap.payload.data() as any;
          if (!userData) {
            return;
          }

          const user = User.createUser(docSnap.payload.id, userData);

          if (userData.type == UserType.CANDIDATE && !userData.onboarded) {
            return from([user]);
          }

          switch (user.type) {
            case UserType.CANDIDATE: {
              if (userData.settings) {
                user.settings = CandidateUserSettings.buildSettings(
                  userData.settings
                );
              }

              const candidateRef = userData.candidate as DocumentReference;
              return this.candidateService.getCandidate(candidateRef.id).pipe(
                map((candidate) => {
                  user.profile = candidate;
                  return user;
                })
              );
            }
            case UserType.BUSINESS: {
              const businessRef = userData.business as DocumentReference;
              return this.getBusiness(businessRef.id).pipe(
                map((business) => {
                  user.profile = business;
                  return user;
                })
              );
            }
          }
        })
      )
      .pipe(
        flatMap((user) => {
          return user;
        })
      );
  }

  getBusiness(businessID) {
    let candidatesCollection = this.afs.collection("businesses");
    return candidatesCollection
      .doc(businessID)
      .get()
      .pipe(
        map((docSnap) => {
          const userData = docSnap.data();

          return Business.createBusiness(businessID, userData);
        })
      );
  }
}
