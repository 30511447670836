<div class="lime-header" *ngIf="currentUser | async as currentUser">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#" [routerLink]="['/']"
        ><img src="assets/images/logo_horizontal.svg" />
      </a>
      <span>Admin</span>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="toggleMobileMenu()"
      >
        <i class="fas fa-bars"></i>
      </button>

      <div
        class="collapse navbar-collapse"
        [class.show]="showMobileMenu"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle theme-settings-link"
              [routerLink]="['dashboard']"
            >
              Dashboard
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle theme-settings-link"
              [routerLink]="['candidates']"
            >
              Candidates
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [routerLink]="['businesses']"
            >
              Businesses
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [routerLink]="['connections']"
            >
              Connections
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [routerLink]="['settings']"
            >
              Settings
            </a>
          </li>

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Favourite Candidates
            </a>
          </li> -->
          <li class="nav-item" ngbDropdown>
            <a
              id="dropdownBasic2"
              role="button"
              class="nav-link"
              ngbDropdownToggle
            >
              <i class="fas fa-user-circle"></i>
              <strong>&nbsp; {{ currentUser.fullname }}</strong>
            </a>

            <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <!-- <li ngbDropdownItem>
                <a [routerLink]="['settings']" ngbDropdownItem href="#"
                  >Settings</a
                >
              </li> -->
              <li class="divider"></li>
              <li ngbDropdownItem>
                <a (click)="logout()" ngbDropdownItem href="#">Log Out</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<div class="lime-container">
  <div class="lime-body">
    <div class="container">
      <router-outlet
        *ngIf="currentUser | async as currentUser; else currentUserLoading"
      ></router-outlet>
      <ng-template #currentUserLoading> LOADING </ng-template>
    </div>
  </div>

  <div class="lime-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <span class="footer-text text-center"
            >All Rights Reserved, 2020 &copy; Talent Toute</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
