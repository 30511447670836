import { BlogArticle } from 'src/app/tt-models/blog_article.model';
import { DiscountCode } from './../../tt-models/discount_code.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Skill } from './../../tt-models/skill.model';
import { Qualification } from './../../tt-models/qualification.model';
import { JobRole } from './../../tt-models/job-role.model';
import { JobType } from './../../tt-models/job-type.model';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { SubscriptionItem } from 'src/app/tt-models/subscription-item.model';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent implements OnInit {
  loadedSetting = '';
  jobRoles: JobRole[];
  jobTypes: JobType[];
  industries: any[];
  suggestedQualifications: any[];
  suggestedJobRoles: any[];
  qualifications: Qualification[];
  suggestedSkills: any[];
  skills: Skill[];
  selectedItem: { id: number; name: string };
  discountCodes: DiscountCode[];
  subscriptions: SubscriptionItem[];

  section = '';

  discountCodeLabel = null;
  discountCodePercentage = null;

  newSubscriptionName = null;
  newSubscriptionPrice = null;
  newSubscriptionCredits = null;
  subToEdit: SubscriptionItem = null;

  articles: BlogArticle[];

  @ViewChild('editValueModal')
  public editValueModal: TemplateRef<any>;

  @ViewChild('newDiscountCodeModal')
  public newDiscountCodeModal: TemplateRef<any>;

  @ViewChild('newSubscriptionModal')
  public newSubscriptionModal: TemplateRef<any>;

  constructor(
    private af: AngularFirestore,
    private modalService: NgbModal,
    private _clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {
    this.loadSettings();
  }

  private async getCandidateDocByUID(uid) {
    const userQuery = await this.af
      .collection('users')
      .doc(uid)
      .get()
      .toPromise();
    if (!userQuery.exists) return;
    const userData = userQuery.data();
    return await (userData.candidate as DocumentReference).get();
  }

  loadSection(section: string) {
    this.section = section;

    switch (section) {
      case 'articles':
        this.refreshArticles();
        break;
      case 'discountcodes':
        this.refreshDiscountCodes();
        break;
      case 'subscriptions':
        this.refreshSubscriptions();
        break;
    }
  }

  addSubscription() {
    this.modalService
      .open(this.newSubscriptionModal, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  addDiscountCode() {
    this.modalService
      .open(this.newDiscountCodeModal, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  async refreshArticles() {
    const subscriptionsQuery = await this.af
      .collection('blog_articles')
      .get()
      .toPromise();

    if (!subscriptionsQuery.empty) {
      this.articles = subscriptionsQuery.docs.map((doc) => {
        return BlogArticle.createArticle(doc.id, doc.data());
      });
    }
  }

  copySubscriptionToClipboard(subscription: SubscriptionItem) {
    this._clipboardService.copy(subscription.publicLink);
    return false;
  }

  async refreshSubscriptions() {
    const subscriptionsQuery = await this.af
      .collection('subscriptions')
      .get()
      .toPromise();

    if (!subscriptionsQuery.empty) {
      this.subscriptions = subscriptionsQuery.docs.map((doc) => {
        return SubscriptionItem.createSubscriptionItem(doc.id, doc.data());
      });
    }
  }

  async saveSubscription() {
    const subName = this.newSubscriptionName
      .replace(new RegExp(' ', 'g'), '-')
      .toLowerCase();

    const subscriptionsItem = new SubscriptionItem(
      null,
      subName,
      this.newSubscriptionName,
      this.newSubscriptionPrice,
      this.newSubscriptionCredits,
      ['card'],
      10
    );
    const discountCodesQuery = await this.af
      .collection('subscriptions')
      .doc(subName)
      .set(subscriptionsItem.toJson());
    if (this.subToEdit) {
      this.subToEdit = null;
      Swal.fire(
        'Subscription updated',
        'Subscription has been updated',
        'success'
      );
    } else {
      Swal.fire(
        'Subscription added',
        'New subscription has been added',
        'success'
      );
    }

    this.modalService.dismissAll();
    this.refreshSubscriptions();
  }

  async refreshDiscountCodes() {
    const discountCodesQuery = await this.af
      .collection('global')
      .doc('static')
      .collection('discount_codes')
      .get()
      .toPromise();

    if (!discountCodesQuery.empty) {
      this.discountCodes = discountCodesQuery.docs.map((doc) => {
        return DiscountCode.createDiscountCode(doc.id, doc.data());
      });
    }
  }

  async deleteDiscountCode(id) {
    try {
      await this.af
        .collection('global')
        .doc('static')
        .collection('discount_codes')
        .doc(id)
        .delete();
      this.refreshDiscountCodes();
    } catch (e) {
    } finally {
    }
  }

  async saveDiscountCode() {
    try {
      await this.af
        .collection('global')
        .doc('static')
        .collection('discount_codes')
        .add({
          label: this.discountCodeLabel,
          percentage: this.discountCodePercentage,
        });
      this.refreshDiscountCodes();
    } catch (e) {
    } finally {
      this.discountCodeLabel = null;
      this.discountCodePercentage = null;

      this.modalService.dismissAll();
    }
  }

  private async removeUserSkillSuggestion(suggested) {
    try {
      const candidateDoc = await this.getCandidateDocByUID(suggested.uid);
      if (!candidateDoc.exists) return;
      const candidateData = candidateDoc.data();
      await candidateDoc.ref.update({
        skills: candidateData.skills.filter((el) => {
          return el.id != suggested.id;
        }),
      });
    } catch (e) {}
  }

  private async removeUserQualificationSuggestion(suggested) {
    try {
      const candidateDoc = await this.getCandidateDocByUID(suggested.uid);
      if (!candidateDoc.exists) return;
      const candidateData = candidateDoc.data();
      await candidateDoc.ref.update({
        qualifications: candidateData.qualifications.filter((el) => {
          return el.id != suggested.id;
        }),
      });
    } catch (e) {}
  }

  private async updateUserjobRole(uid, newJobRoleId) {
    try {
      const candidateDoc = await this.getCandidateDocByUID(uid);
      if (!candidateDoc.exists) return;
      const candidateData = candidateDoc.data();
      const jobRole = this.jobRoles.find((el) => {
        return el.id == newJobRoleId;
      });

      await candidateDoc.ref.update({ jobRole: jobRole });
    } catch (e) {}
  }

  async denySuggested(suggested) {
    const confirmResult = await Swal.fire({
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete this suggestion?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (confirmResult.isDismissed) {
      return;
    }
    try {
      const settings = await this.af
        .collection('global')
        .doc('static')
        .get()
        .toPromise();

      switch (this.loadedSetting) {
        case 'skills':
          await this.removeUserSkillSuggestion(suggested);

          //Remove suggestion
          this.suggestedSkills = this.suggestedSkills.filter((el) => {
            return el.id != suggested.id;
          });

          await settings.ref.update({ suggestedSkills: this.suggestedSkills });

          break;
        case 'qualifications':
          await this.removeUserQualificationSuggestion(suggested);

          //Remove suggestion
          this.suggestedQualifications = this.suggestedQualifications.filter(
            (el) => {
              return el.id != suggested.id;
            }
          );

          await settings.ref.update({
            suggestedQualifications: this.suggestedQualifications,
          });
          break;
        case 'jobRoles':
          const inputOptions = {};
          this.jobRoles.forEach((el) => {
            inputOptions[el.id] = el.name;
          });
          const { value: newJobRole } = await Swal.fire({
            title: 'Select Job Role',
            text:
              'Select a job role to assign instead to the user that suggested it.',
            input: 'select',
            inputOptions: inputOptions,
            inputPlaceholder: 'Select a job role',
            showCancelButton: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  resolve('');
                } else {
                  resolve('You need to select a job role');
                }
              });
            },
          });

          if (newJobRole) {
            await this.updateUserjobRole(suggested.uid, newJobRole);

            //Remove suggestion
            this.suggestedJobRoles = this.suggestedJobRoles.filter((el) => {
              return el.id != suggested.id;
            });

            await settings.ref.update({
              suggestedJobRoles: this.suggestedJobRoles,
            });
          } else {
            return false;
          }

          break;
        default:
          break;
      }

      Swal.fire(
        'Suggestion removed',
        'The suggestion has been removed.',
        'success'
      );
      return true;
    } catch (e) {}
  }

  async acceptSuggested(suggested) {
    const { value: suggestedText } = await Swal.fire({
      title: 'Modify suggestion?',
      input: 'text',
      inputValue: suggested.name,
      showCancelButton: true,
    });

    if (!suggestedText) {
      return false;
    }

    suggested.name = suggestedText;
    const item = this.addItem(suggested.name);

    //Update Users
    try {
      const userQuery = await this.af
        .collection('users')
        .doc(suggested.uid)
        .get()
        .toPromise();
      if (!userQuery.exists) return;
      const userData = userQuery.data();
      const candidateDoc = await (userData.candidate as DocumentReference).get();
      if (!candidateDoc.exists) return;
      const candidateData = candidateDoc.data();

      const settings = await this.af
        .collection('global')
        .doc('static')
        .get()
        .toPromise();

      switch (this.loadedSetting) {
        case 'skills':
          const oldSkills = candidateData.skills;

          oldSkills.forEach((skill) => {
            if (skill.id == suggested.id) {
              skill.id = item.id;
              skill.name = item.name;
              delete skill['custom'];
            }
          });

          await candidateDoc.ref.update({ skills: oldSkills });

          //Remove suggestion
          this.suggestedSkills = this.suggestedSkills.filter((el) => {
            return el.id != suggested.id;
          });

          await settings.ref.update({ suggestedSkills: this.suggestedSkills });

          break;
        case 'qualifications':
          const oldQualifications = candidateData.qualifications;

          oldQualifications.forEach((qualification) => {
            if (qualification.id == suggested.id) {
              qualification.id = item.id;
              qualification.name = item.name;
              delete qualification['custom'];
            }
          });

          await candidateDoc.ref.update({ qualifications: oldQualifications });

          //Remove suggestion
          this.suggestedQualifications = this.suggestedQualifications.filter(
            (el) => {
              return el.id != suggested.id;
            }
          );

          await settings.ref.update({
            suggestedQualifications: this.suggestedQualifications,
          });
          break;
        case 'jobRoles':
          delete item['custom'];
          await candidateDoc.ref.update({ jobRole: item });

          //Remove suggestion
          this.suggestedJobRoles = this.suggestedJobRoles.filter((el) => {
            return el.id != suggested.id;
          });

          await settings.ref.update({
            suggestedJobRoles: this.suggestedJobRoles,
          });
          break;
        default:
          break;
      }

      Swal.fire(
        'Suggestion added',
        'The suggestion has been added.',
        'success'
      );
      return true;
    } catch (e) {}
  }

  async loadSetting(setting) {
    this.section = 'settings';
    this.loadedSetting = setting;
  }

  addItem(name) {
    const settings = this[this.loadedSetting];
    const newId = settings.length + 4000 + 1;
    const item = {
      id: newId,
      name: name,
    };
    settings.push(item);
    this.updateGlobalStatic(this.loadedSetting, settings);
    return item;
  }

  async addJobRole() {
    const { value: jobRole } = await Swal.fire({
      title: 'Add new Job Role',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
    });

    if (jobRole) {
      await this.addItem(jobRole);
    }
  }

  async addQualification() {
    const { value: jobRole } = await Swal.fire({
      title: 'Add new qualification ',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
    });

    if (jobRole) {
      await this.addItem(jobRole);
    }
  }

  async editSubscription(subscription: SubscriptionItem) {
    this.subToEdit = subscription;
    this.newSubscriptionName = subscription.name;
    this.newSubscriptionCredits = subscription.credits;
    this.newSubscriptionPrice = subscription.price;
    this.modalService
      .open(this.newSubscriptionModal, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  async deleteSubscription(subscription: SubscriptionItem) {
    const confirmResult = await Swal.fire({
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (confirmResult.isConfirmed) {
      const discountCodesQuery = await this.af
        .collection('subscriptions')
        .doc(subscription.name)
        .delete();

      Swal.fire('Subscription deleted', 'Subscription deleted', 'success');

      this.refreshSubscriptions();
    }
  }

  async addIndustry() {
    const { value: jobRole } = await Swal.fire({
      title: 'Add new industry ',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
    });

    if (jobRole) {
      await this.addItem(jobRole);
    }
  }

  async addJobType() {
    const { value: jobRole } = await Swal.fire({
      title: 'Add new Job Type ',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
    });

    if (jobRole) {
      await this.addItem(jobRole);
    }
  }

  async addSkill() {
    const { value: jobRole } = await Swal.fire({
      title: 'Add new skill ',
      input: 'text',
      inputValue: '',
      showCancelButton: true,
    });

    if (jobRole) {
      await this.addItem(jobRole);
    }
  }

  async loadSettings() {
    const settings = await this.af
      .collection('global')
      .doc('static')
      .get()
      .toPromise();

    const data = settings.data();

    this.jobRoles = data.jobRoles;
    this.jobTypes = data.jobTypes;
    this.industries = data.industries;
    this.qualifications = data.qualifications;
    this.skills = data.skills;
    this.suggestedSkills = data.suggestedSkills;
    this.suggestedQualifications = data.suggestedQualifications;
    this.suggestedJobRoles = data.suggestedJobRoles;
  }

  async deleteItem(item) {
    this.selectedItem = item;
    const confirmResult = await Swal.fire({
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (confirmResult.isConfirmed) {
      this[this.loadedSetting] = this[this.loadedSetting].filter((el) => {
        return el.id !== this.selectedItem.id;
      });

      this.updateGlobalStatic(this.loadedSetting, this[this.loadedSetting]);
    }
  }

  updateItem() {
    this[this.loadedSetting].forEach((el) => {
      if (el.id == this.selectedItem.id) {
        el.name = this.selectedItem.name;
      }
    });

    this.updateGlobalStatic(this.loadedSetting, this[this.loadedSetting]);

    //Update Collection

    this.modalService.dismissAll();
  }

  async updateGlobalStatic(name, data) {
    const update = {};
    update[name] = data.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    const settings = await this.af
      .collection('global')
      .doc('static')
      .update(update);
  }

  editJobRole(role) {
    this.selectedItem = role;
    this.modalService
      .open(this.editValueModal, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
}
