import { Industry } from "./industry.model";
import { User } from "src/app/tt-models/user.model";
import { Address } from "./address.model";

export class Business {
  id: String;
  name: String;
  companyNumber: String;
  website: String;
  telephone: String;
  industry: Industry;
  companySize: Number;
  user: User;

  location: {
    city: String;
    country: String;
  };

  constructor() {
    this.id = "";
    this.name = "";
    this.companyNumber = "";
    this.website = "";
    this.telephone = "";
    this.industry = null;
    this.companySize = -1;
    this.location = {
      city: "",
      country: "GB",
    };
  }

  get shortID() {
    return this.id.substr(0, 6);
  }

  static createBusiness(id: String, data: any) {
    if (!data) return null;
    let business = new Business();
    business.id = id;
    business.name = data.name;

    business.industry = data.industry;

    business.companyNumber = data.businessNumber;
    business.telephone = data.telephoneNumber;
    business.website = data.website;
    business.companySize = data.companySize;

    let businessAddress = new Address();
    businessAddress.line1 = data.address.line1;
    businessAddress.city = data.address.city;

    business.location = businessAddress;

    return business;
  }
}
