import { Business } from './../../tt-models/business.model';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/tt-services/auth/auth.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/tt-models/user.model';
import { AdminAuthService } from 'src/app/services/adminauth/adminauth.service';
import { AdminUser } from 'src/app/models/adminuser.model';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  currentUser: Observable<AdminUser>;
  showMobileMenu = false;
  constructor(private authService: AdminAuthService, private router: Router) {
    this.currentUser = this.authService.currentUserObserver;
    this.currentUser.subscribe((user) => {});
  }

  ngOnInit(): void {}

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['login']);
  }
}
