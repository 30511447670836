import { Address } from "./address.model";
import { Conversation } from "src/app/tt-models/conversation.model";
import { Candidate } from "./candidate.model";
import { Business } from "./business.model";
import { JobInvitationStatus } from "./jobs-invitation-status.enum";
export class JobInvitation {
  id: String;
  candidate: Candidate;
  business: Business;
  status: JobInvitationStatus;
  date: Date;
  conversation: Conversation;
  location: Address;

  constructor() {
    this.id = "";
    this.candidate = new Candidate();
    this.business = new Business();
    this.status = JobInvitationStatus.ACCEPTED;
    this.date = new Date();
    this.conversation = null;
    this.location = new Address();
  }

  isAccepted() {
    return (
      [
        JobInvitationStatus.ACCEPTED,
        JobInvitationStatus.NOTHIRED,
        JobInvitationStatus.REQUESTHIRED,
        JobInvitationStatus.HIRED,
      ].indexOf(this.status) > -1
    );
  }

  isPending() {
    return this.status == JobInvitationStatus.SENT;
  }

  isDeclined() {
    return this.status == JobInvitationStatus.DECLINED;
  }

  canHire() {
    return (
      [
        JobInvitationStatus.SENT,
        JobInvitationStatus.NOTHIRED,
        JobInvitationStatus.REQUESTHIRED,
        JobInvitationStatus.HIRED,
        JobInvitationStatus.DECLINED,
      ].indexOf(this.status) == -1
    );
  }
  get statusLabel() {
    switch (this.status) {
      case JobInvitationStatus.ACCEPTED:
        return "Accepted";
        break;
      case JobInvitationStatus.SENT:
        return "Sent to Candidate";
        break;
      case JobInvitationStatus.DECLINED:
        return "Declined";
        break;
      case JobInvitationStatus.REQUESTHIRED:
        return "Employment requested";
        break;
      case JobInvitationStatus.HIRED:
        return "Employment Accepted";
        break;
      case JobInvitationStatus.HIRED:
        return "Employment Denied";
        break;
    }
  }
}
