export class ConversationMessage {
  id: String;
  sender: String;
  type: number;
  content: any;
  date: Date;

  constructor() {
    this.id = "";
    this.sender = "";
    this.type = 0;
    this.content = "";
    this.date = new Date();
  }

  static createNewTextMessage(senderUID: string, text: string) {
    const conversationMessage = new ConversationMessage();
    conversationMessage.date = new Date();
    conversationMessage.sender = senderUID;
    conversationMessage.type = 0;
    conversationMessage.content = { text };
    return conversationMessage;
  }

  static createHireRequestMessage(senderUID: string) {
    const conversationMessage = new ConversationMessage();
    conversationMessage.date = new Date();
    conversationMessage.sender = senderUID;
    conversationMessage.type = 2;
    conversationMessage.content = {};
    return conversationMessage;
  }

  static createNewDocumentMessage(
    senderUID: String,
    url: string,
    filename: string,
    type: string
  ) {
    const conversationMessage = new ConversationMessage();
    conversationMessage.date = new Date();
    conversationMessage.sender = senderUID;
    conversationMessage.type = 1;
    conversationMessage.content = { url, type, filename };
    return conversationMessage;
  }
}
