import { ConversationMessage } from "src/app/tt-models/conversation-message.model";
import { Injectable } from "@angular/core";
import { map, take, flatMap, catchError } from "rxjs/operators";
import {
  DocumentReference,
  AngularFirestore,
  DocumentSnapshot,
} from "@angular/fire/firestore";
import {
  Conversation,
  ConversationStatus,
  ConversationType,
} from "src/app/tt-models/conversation.model";
import { from, forkJoin, combineLatest, throwError, of } from "rxjs";
import { Business } from "src/app/tt-models/business.model";
import { Address } from "src/app/tt-models/address.model";
import { Candidate } from "src/app/tt-models/candidate.model";
import { UserService } from "../user/user.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { UploadTaskSnapshot } from "@angular/fire/storage/interfaces";
import * as firebase from "firebase";
import { User } from "src/app/tt-models/user.model";

@Injectable({
  providedIn: "root",
})
export class ConversationService {
  constructor(
    private af: AngularFirestore,
    private userService: UserService,
    private afstorage: AngularFireStorage
  ) {}

  async resetUserLastRead(userId: string) {
    const conversationsCollection = this.af.collection("users");

    const conversationDoc = conversationsCollection.doc(userId);

    try {
      conversationDoc.update({
        unreadMessageCount: 0,
        lastMessageReadAt: new Date(),
      });
    } catch (e) {}
  }

  async closeConversation(conversationId: String) {
    const conversationsCollection = this.af.collection("conversations");

    const conversationDoc = conversationsCollection.doc(
      conversationId.toString()
    );

    const conversationQuery = await conversationDoc.get().toPromise();

    if (conversationQuery.exists) {
      await conversationQuery.ref.update({ status: ConversationStatus.CLOSED });
      return true;
    }

    return false;
  }

  updateConverastionRead(conversationId: String, userId: String) {
    const conversationsCollection = this.af.collection("conversations");

    const conversationDoc = conversationsCollection.doc(
      conversationId.toString()
    );

    conversationDoc
      .get()
      .pipe(
        map((conversationDocSnap) => {
          const data = conversationDocSnap.data();
          if (!data) {
            return;
          }
          let lastReads: any = data.lastReads || {};
          if (userId) {
            lastReads[userId.toString()] = new Date();
          }

          conversationDocSnap.ref.update({
            lastReads,
          });
        })
      )
      .subscribe(() => {});
  }

  getConversationByUsers(candidateId: String, businessId: String) {
    const businessCollection = this.af.collection("businesses");
    const businessRef = businessCollection.doc(businessId.toString()).ref;
    const candidateCollection = this.af.collection("candidates");
    const candidateRef = candidateCollection.doc(candidateId.toString()).ref;
    const conversationsCollection = this.af.collection(
      "conversations",
      (ref) => {
        return ref
          .where("business", "==", businessRef)
          .where("candidate", "==", candidateRef);
      }
    );

    return conversationsCollection
      .get()
      .pipe(
        map((conversationSnap) => {
          if (!conversationSnap.empty) {
            return this.resolveConversationSnap(conversationSnap.docs[0]);
          }
          return of(null);
        })
      )
      .pipe(
        flatMap((candidates) => {
          return candidates;
        })
      );
  }

  sendMessage(conversationId: String, message: ConversationMessage) {
    const conversationsCollection = this.af.collection("conversations");

    const conversationDoc = conversationsCollection.doc(
      conversationId.toString()
    );

    return conversationDoc
      .get()
      .pipe(
        map((conversationDocSnap) => {
          if (conversationDocSnap.exists) {
            const messagesCollection = conversationDoc.collection("messages");
            return from(
              messagesCollection.add({
                date: message.date,
                sender: message.sender,
                type: message.type,
                content: message.content,
              })
            );
          }
        })
      )
      .pipe(
        flatMap((response) => {
          return response;
        })
      );
  }

  async acceptHire(converstion: Conversation, message: ConversationMessage) {
    if (message.type == 2) {
      const doc = await this.af
        .collection("conversations")
        .doc(converstion.id.toString())
        .collection("messages")
        .doc(message.id.toString())
        .get({})
        .toPromise();
      if (doc.exists) {
        var content = message.content;
        content["status"] = 1;
        await doc.ref.update({ content: content });
        await doc.ref.parent.parent.update({
          status: ConversationStatus.HIRED,
        });
        return true;
      }
    }

    return false;
  }

  async denyHire(converstion: Conversation, message: ConversationMessage) {
    if (message.type == 2) {
      const doc = await this.af
        .collection("conversations")
        .doc(converstion.id.toString())
        .collection("messages")
        .doc(message.id.toString())
        .get({})
        .toPromise();
      if (doc.exists) {
        var content = message.content;
        content["status"] = 2;
        await doc.ref.update({ content: content });

        return true;
      }
    }

    return false;
  }

  getConversationMessages(conversationId) {
    const conversationsCollection = this.af.collection("conversations");

    const conversationSnap = conversationsCollection.doc(conversationId).get();

    const conversationMessagesCollection = conversationsCollection
      .doc(conversationId)
      .collection("messages", (ref) => ref.orderBy("date", "desc").limit(8))
      .snapshotChanges();
    return conversationMessagesCollection
      .pipe(
        map((actions) =>
          actions.map((a) => {
            return a.payload.doc;
          })
        )
      )
      .pipe(map((messages) => messages.reverse()))
      .pipe(
        map((docs) => {
          return docs.map((doc) => {
            const conversationMessageData = doc.data();
            const conversationMessage = new ConversationMessage();
            conversationMessage.id = doc.id;
            conversationMessage.type = conversationMessageData.type;
            conversationMessage.content = conversationMessageData.content;
            conversationMessage.date = (conversationMessageData.date as firebase.default.firestore.Timestamp).toDate();
            conversationMessage.sender = conversationMessageData.sender;

            return conversationMessage;
          });
        })
      );
    // return this.resolveConversationSnap(conversationSnap).pipe(
    //   map((conversation) => {

    //     );
    //   })
    // )
    // .pipe(
    //   flatMap((messages) => {
    //     return messages;
    //   })
    // );
  }

  getCandidateUserCovnersations(candidateUid) {
    const conversationsCollection = this.af
      .collection("conversations", (ref) => {
        return ref
          .where(
            "candidate",
            "==",
            this.af.collection("candidates").doc(candidateUid).ref
          )
          .where("type", "==", ConversationType.EMPLOYER_CHAT);
      })
      .get();

    const conversations = conversationsCollection
      .pipe(
        catchError((e: any) => {
          return throwError(e);
        })
      )
      .pipe(
        map((data) => {
          return data.docs.map(async (doc) => {
            return await this.resolveConversationSnap(doc);
          });
        })
      )
      .pipe(
        flatMap((conversations) => {
          if (conversations.length > 0) {
            return combineLatest(conversations);
          } else {
            return of(null);
          }
        })
      );

    return conversations;
  }

  watchUserConversations() {
    const conversationsCollection = this.af
      .collection("conversations", (ref) => {
        return ref.where("type", "==", ConversationType.EMPLOYER_CHAT);
      })
      .snapshotChanges();

    const conversations = conversationsCollection
      .pipe(
        catchError((e: any) => {
          return throwError(e);
        })
      )
      .pipe(
        map((data) => {
          return data.map(async (doc) => {
            return await this.resolveConversationSnap(doc.payload.doc);
          });
        })
      )
      .pipe(
        flatMap((conversations) => {
          if (conversations.length > 0) {
            return combineLatest(conversations);
          } else {
            return of(null);
          }
        })
      );

    return conversations;
  }

  getUserConversations() {
    const conversationsCollection = this.af
      .collection("conversations", (ref) => {
        return ref.where("type", "==", ConversationType.EMPLOYER_CHAT);
      })
      .get();

    const conversations = conversationsCollection
      .pipe(
        catchError((e: any) => {
          return throwError(e);
        })
      )
      .pipe(
        map((data) => {
          return data.docs.map((doc) => {
            return this.resolveConversationSnap(doc);
          });
        })
      )
      .pipe(
        flatMap((conversations) => {
          if (conversations.length > 0) {
            return combineLatest(conversations);
          } else {
            return of(null);
          }
        })
      );

    return conversations;
  }

  getConversation(converationId) {
    const conversationsSnap = this.af
      .collection("conversations")
      .doc(converationId)
      .get();
    return conversationsSnap
      .pipe(
        map((doc) => {
          return this.resolveConversationSnap(doc);
        })
      )
      .pipe(
        flatMap((conversations) => {
          return conversations;
        })
      );
  }

  async resolveConversationSnap(doc) {
    const conversationData = doc.data();
    const businessRef = conversationData.business as DocumentReference;
    const candidateRef = conversationData.candidate as DocumentReference;

    const conversation = new Conversation();
    conversation.id = doc.id;
    conversation.status = conversationData.status;
    conversation.type = conversationData.type || ConversationType.EMPLOYER_CHAT;
    conversation.lastMessage = conversationData.lastMessage;

    conversation.lastMessageFrom = conversationData.lasMessageFrom;
    conversation.lastMessageDate = conversationData.lastMessageDate
      ? (conversationData.lastMessageDate as firebase.default.firestore.Timestamp).toDate()
      : null;

    if (conversationData.lastReads) {
      for (let key in conversationData.lastReads) {
        conversationData.lastReads[key] = (conversationData.lastReads[
          key
        ] as firebase.default.firestore.Timestamp).toDate();
      }
    }

    conversation.lastReads = conversationData.lastReads || {};

    var getCandidate = new Promise<Candidate>(async (resolve, reject) => {
      const candidateSnap = await candidateRef.get();
      const candidateData = candidateSnap.data();
      if (candidateData) {
        let userQuery = await this.af
          .collection("users", (ref) => {
            return ref.where("candidate", "==", candidateSnap.ref);
          })
          .get()
          .toPromise();

        const candidate = Candidate.createCandidate(
          candidateSnap.id,
          candidateData
        );

        if (!userQuery.empty) {
          const userDoc = userQuery.docs[0];
          candidate.user = User.createUser(userDoc.id, userDoc.data());
        }

        resolve(candidate);
      } else {
        resolve(null);
      }
    });

    if (conversation.isEmployerChat) {
      const getBusiness = from(businessRef.get())
        .pipe(take(1))
        .pipe(
          map((businessSnapshop) => {
            const businessData = businessSnapshop.data();
            return Business.createBusiness(businessSnapshop.id, businessData);
          })
        )
        .toPromise();

      conversation.business = await getBusiness;
      conversation.candidate = await getCandidate;

      return conversation;
    } else {
      conversation.candidate = await getCandidate;

      return conversation;
    }
  }

  uploadFile(conversation: Conversation, document: any, documentType: string) {
    const filename = new Date().getTime();
    const ref = this.afstorage.ref(
      "messaging_data/conversation-" + conversation.id + "/" + filename
    );

    return new Promise<any>(async (resolve, reject) => {
      const a: UploadTaskSnapshot = await ref.put(document);
      if (a.state === firebase.default.storage.TaskState.SUCCESS) {
        await a.ref.updateMetadata({
          contentType: documentType,
        });
        const url = await a.ref.getDownloadURL();

        resolve(url);
      }
    });
  }
}
