export class DiscountCode {
  id: string;
  label: string;
  percentage: number;

  constructor(id: string, label: string, percentage: number) {
    this.id = id;
    this.label = label;
    this.percentage = percentage;
  }

  static createDiscountCode(id, data) {
    return new DiscountCode(id, data.label || "", data.percentage || 0);
  }
}
