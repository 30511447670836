<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <div class="todo-sidebar">
              <div class="todo-menu">
                <h3>Content</h3>

                <ul class="list-unstyled">
                  <li>
                    <a (click)="loadSection('articles')">Articles</a>
                  </li>
                </ul>
                <h3>Sales</h3>

                <ul class="list-unstyled">
                  <li>
                    <a (click)="loadSection('subscriptions')">Subscriptions</a>
                  </li>
                  <li>
                    <a (click)="loadSection('discountcodes')">Discount Codes</a>
                  </li>
                </ul>
                <h3>Settings to Edit</h3>
                <ul class="list-unstyled">
                  <li>
                    <a (click)="loadSetting('jobRoles')">Edit Job Roles</a>
                  </li>
                  <li>
                    <a (click)="loadSetting('industries')">Edit Industries</a>
                  </li>
                  <li>
                    <a (click)="loadSetting('jobTypes')">Edit Job Types</a>
                  </li>
                  <li>
                    <a (click)="loadSetting('skills')">Edit Skills</a>
                  </li>
                  <li>
                    <a (click)="loadSetting('qualifications')"
                      >Edit Qualifications</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-md-9" *ngIf="section == 'articles'">
            <h4>Articles</h4>
            <button class="btn btn-primary mb-3">Add Article</button>
            <table class="table">
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>

              <tr *ngFor="let article of articles">
                <td>{{ article.title }}</td>
                <td>{{ article.created | date }}</td>
                <td><span class="badge badge-success">In-App</span></td>
                <td>
                  <div class="btn-group">
                    <button class="btn btn-primary">View</button>
                    <button class="btn btn-primary">Edit</button>
                    <button class="btn btn-danger">Delete</button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-9" *ngIf="section == 'subscriptions'">
            <h4>Subscriptions</h4>
            <button class="btn btn-primary mb-3" (click)="addSubscription()">
              Add Subscription
            </button>
            <table class="table">
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Credits</th>
                <th>Payment Methods</th>
                <th></th>
              </tr>

              <tr *ngFor="let subscription of subscriptions">
                <td>
                  {{ subscription.label }} ({{ subscription.name }}) <br />
                  <a
                    href="#"
                    (click)="copySubscriptionToClipboard(subscription)"
                    >Copy Share Link</a
                  >
                </td>
                <td>&pound;{{ subscription.price }}</td>
                <td>{{ subscription.credits }}</td>
                <td>{{ subscription.acceptedPayments.join(",") }}</td>
                <td>
                  <button
                    class="btn btn-primary mr-2"
                    (click)="editSubscription(subscription)"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-danger"
                    (click)="deleteSubscription(subscription)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-9" *ngIf="section == 'discountcodes'">
            <h4>Discount codes</h4>
            <button class="btn btn-primary mb-3" (click)="addDiscountCode()">
              Add Discount
            </button>
            <table class="table">
              <tr>
                <th>Label</th>
                <th>Discount</th>
                <th></th>
              </tr>

              <tr *ngFor="let discountCode of discountCodes">
                <td>{{ discountCode.label }}</td>
                <td>{{ discountCode.percentage }}%</td>
                <td>
                  <button
                    class="btn btn-danger"
                    (click)="deleteDiscountCode(discountCode.id)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-9" *ngIf="section == 'settings'">
            <div class="settings-page" *ngIf="loadedSetting == 'jobRoles'">
              <button class="btn btn-primary mb-3" (click)="addJobRole()">
                Add Job Role
              </button>
              <div>
                <strong>Suggested List</strong>
                <p *ngIf="suggestedJobRoles.length == 0" class="text-center">
                  No suggestions
                </p>
                <ul class="settings-list" *ngIf="suggestedJobRoles.length > 0">
                  <li *ngFor="let suggestedJobRole of suggestedJobRoles">
                    {{ suggestedJobRole.name }}
                    <div style="float: right">
                      <a
                        class="text-primary"
                        (click)="acceptSuggested(suggestedJobRole)"
                        ><i class="fas fa-check"></i
                      ></a>
                      &nbsp;
                      <a
                        class="text-danger"
                        (click)="denySuggested(suggestedJobRole)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <strong>Live List</strong>
                <ul class="settings-list">
                  <li *ngFor="let role of jobRoles">
                    {{ role.name }}
                    <div style="float: right">
                      <a class="text-primary" (click)="editJobRole(role)"
                        ><i class="fas fa-edit"></i
                      ></a>
                      &nbsp;
                      <a class="text-danger" (click)="deleteItem(role)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="settings-page" *ngIf="loadedSetting == 'jobTypes'">
              <button class="btn btn-primary mb-3" (click)="addJobType()">
                Add Job Type
              </button>
              <ul class="settings-list">
                <li *ngFor="let jobType of jobTypes">
                  {{ jobType.name }}
                  <div style="float: right">
                    <a class="text-primary" (click)="editJobRole(jobType)"
                      ><i class="fas fa-edit"></i
                    ></a>
                    &nbsp;
                    <a class="text-danger" (click)="deleteItem(jobType)"
                      ><i class="fas fa-trash"></i
                    ></a>
                  </div>
                </li>
              </ul>
            </div>

            <div class="settings-page" *ngIf="loadedSetting == 'industries'">
              <button class="btn btn-primary mb-3" (click)="addIndustry()">
                Add Industry
              </button>
              <ul class="settings-list">
                <li *ngFor="let industry of industries">
                  {{ industry.name }}
                  <div style="float: right">
                    <a class="text-primary" (click)="editJobRole(industry)"
                      ><i class="fas fa-edit"></i
                    ></a>
                    &nbsp;
                    <a class="text-danger" (click)="deleteItem(industry)"
                      ><i class="fas fa-trash"></i
                    ></a>
                  </div>
                </li>
              </ul>
            </div>

            <div class="settings-page" *ngIf="loadedSetting == 'skills'">
              <button class="btn btn-primary mb-3" (click)="addSkill()">
                Add Skill
              </button>
              <div>
                <strong>Suggested List</strong>
                <p *ngIf="suggestedSkills.length == 0" class="text-center">
                  No suggestions
                </p>
                <ul class="settings-list" *ngIf="suggestedSkills.length > 0">
                  <li *ngFor="let suggestedSkill of suggestedSkills">
                    {{ suggestedSkill.name }}
                    <div style="float: right">
                      <a
                        class="text-primary"
                        (click)="acceptSuggested(suggestedSkill)"
                        ><i class="fas fa-check"></i
                      ></a>
                      &nbsp;
                      <a
                        class="text-danger"
                        (click)="denySuggested(suggestedSkill)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>

              <div>
                <strong>Live List</strong>
                <ul class="settings-list">
                  <li *ngFor="let skill of skills">
                    {{ skill.name }}
                    <div style="float: right">
                      <a class="text-primary" (click)="editJobRole(skill)"
                        ><i class="fas fa-edit"></i
                      ></a>
                      &nbsp;
                      <a class="text-danger" (click)="deleteItem(skill)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="settings-page"
              *ngIf="loadedSetting == 'qualifications'"
            >
              <button class="btn btn-primary mb-3" (click)="addQualification()">
                Add Qualification
              </button>

              <div>
                <strong>Suggested List</strong>
                <p
                  *ngIf="suggestedQualifications.length == 0"
                  class="text-center"
                >
                  No suggestions
                </p>
                <ul
                  class="settings-list"
                  *ngIf="suggestedQualifications.length > 0"
                >
                  <li
                    *ngFor="
                      let suggestedQualification of suggestedQualifications
                    "
                  >
                    {{ suggestedQualification.name }}
                    <div style="float: right">
                      <a
                        class="text-primary"
                        (click)="acceptSuggested(suggestedQualification)"
                        ><i class="fas fa-check"></i
                      ></a>
                      &nbsp;
                      <a
                        class="text-danger"
                        (click)="denySuggested(suggestedQualification)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <strong>Live List</strong>
                <ul class="settings-list">
                  <li *ngFor="let qualification of qualifications">
                    {{ qualification.name }}
                    <div style="float: right">
                      <a
                        class="text-primary"
                        (click)="editJobRole(qualification)"
                        ><i class="fas fa-edit"></i
                      ></a>
                      &nbsp;
                      <a class="text-danger" (click)="deleteItem(qualification)"
                        ><i class="fas fa-trash"></i
                      ></a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #editValueModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Edit</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input type="text" class="form-control" [(ngModel)]="selectedItem.name" />

      <button type="button" class="mt-3 btn btn-primary" (click)="updateItem()">
        Update
      </button>
    </div>
  </ng-template>

  <ng-template #newDiscountCodeModal let-searchModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add discount code</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="searchModal.dismiss('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Name <small>Don't enter spaces</small></label>

          <input
            type="text"
            maxlength="10"
            class="form-control"
            [(ngModel)]="discountCodeLabel"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label>Discount Percentage</label>
          <input
            type="number"
            class="form-control"
            max="100"
            maxlength="100"
            [(ngModel)]="discountCodePercentage"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="text-center pt-2">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveDiscountCode()"
            [disabled]="!discountCodeLabel || !discountCodePercentage"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #newSubscriptionModal let-searchModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left" *ngIf="!subToEdit">Add subscription</h4>
      <h4 class="modal-title pull-left" *ngIf="subToEdit">Edit subscription</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="searchModal.dismiss('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label>Name </label>

          <input
            type="text"
            maxlength="30"
            class="form-control"
            [(ngModel)]="newSubscriptionName"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label>Price</label>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="newSubscriptionPrice"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="form-group">
          <label>Credits</label>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="newSubscriptionCredits"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <div class="text-center pt-2">
          <button
            type="submit"
            class="btn btn-primary"
            (click)="saveSubscription()"
            [disabled]="
              !newSubscriptionName ||
              !newSubscriptionPrice ||
              !newSubscriptionCredits
            "
          >
            <span *ngIf="!subToEdit">Add</span>
            <span *ngIf="subToEdit">Updated</span>
          </button>
        </div>
      </form>
    </div>
  </ng-template>
</div>
