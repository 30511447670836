import { User } from "./user.model";
import { JobInvitationStatus } from "./jobs-invitation-status.enum";
import { JobInvitation } from "./job-invitation.model";
import { JobSearchType } from "./job-search-type.enum";
import { Qualification } from "./qualification.model";
import { Skill } from "./skill.model";
import { JobSearchArea } from "./job-search-area.enum";
import { JobRole } from "./job-role.model";

export class Candidate {
  id: String;

  user: User;
  name: String;
  bio: String;
  jobRole: JobRole;
  city: String;
  available: Boolean;
  jobSearchType: Array<JobSearchType>;
  jobSearchArea: Array<JobSearchArea>;
  jobStarting: Date;
  jobDuration: Number;
  minimumRate: Number;
  desiredRate: Number;
  annualSalary: Number;
  minimumAnnualSalary: Number;
  jobRadius: Number;
  verified: boolean;
  availability: string;
  jobType: string;

  jobLocationCities: Array<any>;

  skills: Array<Skill>;
  qualifications: Array<Qualification>;

  uid: String;

  location: {
    city: String;
    country: String;
  };

  invitationId: string;
  invitationStatus: JobInvitationStatus;
  invitationDate: Date;

  constructor() {
    this.id = "";
    this.name = "";
    this.bio = "";
    this.jobRole = null;
    this.available = false;
    this.jobSearchType = [];
    this.jobSearchArea = [];
    this.jobStarting = new Date();
    this.jobDuration = 0;
    this.minimumRate = 0;
    this.desiredRate = 0;
    this.annualSalary = 0;
    this.minimumAnnualSalary = 0;
    this.jobRadius = 0;
    this.skills = [];
    this.qualifications = [];
    this.verified = false;
    this.uid = "";
    this.invitationStatus = JobInvitationStatus.NONE;
    this.invitationDate = null;
    this.invitationId = null;
    this.availability = "";
    this.location = {
      city: "",
      country: "GB",
    };
  }

  get shortID() {
    return this.id.substr(0, 6);
  }

  get availableImmediately() {
    return this.availability == "immediately" && this.jobStarting == null;
  }

  get availableWithinMonth() {
    return this.availability == "within_month";
  }

  get availableWithinWeek() {
    return this.availability == "within_week";
  }

  get availableFrom() {
    return this.availability == "from_date";
  }

  get availableForContract() {
    return this.jobSearchType.indexOf(JobSearchType.CONTRACTING) > -1;
  }

  get availableForFixedTerm() {
    return this.jobSearchType.indexOf(JobSearchType.FIXED_TERM) > -1;
  }

  static getCapitalized(str) {
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
    return str.replace(
      /[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g,
      function (match, index, title) {
        if (
          index > 0 &&
          index + match.length !== title.length &&
          match.search(smallWords) > -1 &&
          title.charAt(index - 2) !== ":" &&
          (title.charAt(index + match.length) !== "-" ||
            title.charAt(index - 1) === "-") &&
          (title.charAt(index + match.length) !== "'" ||
            title.charAt(index - 1) === "'") &&
          title.charAt(index - 1).search(/[^\s-]/) < 0
        ) {
          return match.toLowerCase();
        }
        if (match.substr(1).search(/[A-Z]|\../) > -1) {
          return match;
        }
        return match.charAt(0).toUpperCase() + match.substr(1);
      }
    );
  }

  static createCandidate(id: String, data: any) {
    let candidate = new Candidate();
    if (!data) {
      return candidate;
    }
    candidate.id = id;
    candidate.name = Candidate.getCapitalized(data.name);
    candidate.annualSalary = data.annualSalary;
    candidate.minimumAnnualSalary = data.minimumAnnualSalary;
    candidate.desiredRate = data.desiredRate;
    candidate.minimumRate = data.minimumRate;
    candidate.jobDuration = data.jobDuration;
    candidate.jobRadius = data.jobRadius || 0;
    candidate.verified = data.verified;
    candidate.jobSearchArea = data.jobSearchArea
      ? data.jobSearchArea.map((area) => {
          switch (area) {
            case "area":
              return JobSearchArea.AREA;
              break;
            case "city":
              return JobSearchArea.CITY;
              break;
            case "nationwide":
              return JobSearchArea.NATIONWIDE;
              break;
            case "homework":
              return JobSearchArea.HOMEWORK;
              break;
          }
        })
      : [];
    candidate.jobSearchType = data.jobSearchType
      ? data.jobSearchType.map((type) => {
          switch (type) {
            case "contract":
              return JobSearchType.CONTRACTING;
              break;
            case "fixed-term":
              return JobSearchType.FIXED_TERM;
              break;
          }
        })
      : [];
    candidate.qualifications = data.qualifications
      ? data.qualifications.map((qualification) => {
          return new Qualification(qualification.id, qualification.name);
        })
      : [];
    candidate.skills = data.skills
      ? data.skills.map((skill) => {
          return new Skill(skill.id, skill.name);
        })
      : [];
    candidate.jobRole = data.jobRole
      ? new JobRole(data.jobRole.id, data.jobRole.name)
      : null;

    candidate.available = data.available;
    candidate.bio = data.bio;
    candidate.city = data.city;
    candidate.jobType = "";
    candidate.jobDuration = data.jobDuration;
    candidate.jobLocationCities = data.jobLocationCities;
    candidate.availability = data.availability
      ? data.availability
      : data.available
      ? "immediately"
      : "not_available";
    try {
      candidate.jobStarting = data.jobStarting
        ? (data.jobStarting as firebase.default.firestore.Timestamp).toDate()
        : null;
    } catch (e) {}

    return candidate;
  }
}
