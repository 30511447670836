import { JobInvitation } from 'src/app/tt-models/job-invitation.model';
import { Subscription } from 'src/app/tt-models/subscription.model';
import { Conversation } from './../../tt-models/conversation.model';
import { ConversationService } from './../../tt-services/conversation/conversation.service';
import { Business } from './../../tt-models/business.model';
import { Router } from '@angular/router';
import { Observable, of, empty, combineLatest, throwError, from } from 'rxjs';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { map, flatMap, catchError, tap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UserService } from '../../tt-services/user/user.service';
import { User, UserType } from '../../tt-models/user.model';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { AdminUser } from '../../models/adminuser.model';
import { Candidate } from 'src/app/tt-models/candidate.model';
import { ConversationType } from 'src/app/tt-models/conversation.model';
import { TTVerificationStatus } from 'src/app/tt-models/ttverification.model';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private conversationService: ConversationService
  ) {}

  sendCandidateMassNotification(
    title,
    message,
    all = false,
    candidates: Array<any> = null
  ) {
    const callable = this.aff.httpsCallable('sendCandidateMassNotification');

    return callable({ all, candidates, title, message }).toPromise();
  }

  getTodaysWeeklyNotifications() {
    const callable = this.aff.httpsCallable('getWeeklyNotifications');

    return callable({ dayTimestamp: 1611187200 * 1000 }).toPromise();
  }

  getBusiness(businessID: string) {
    let candidatesCollection = this.afs.collection('businesses');
    return candidatesCollection
      .doc(businessID)
      .get()
      .pipe(
        map((docSnap) => {
          const userData = docSnap.data();

          return Business.createBusiness(businessID, userData);
        })
      );
  }

  async getVerificationConversations(): Promise<Conversation[]> {
    const conversationsCollection = await this.afs
      .collection('conversations', (ref) => {
        return ref
          .where('type', '==', ConversationType.VERIFICATION_CHAT)
          .where('status', '==', 0);
      })
      .get()
      .toPromise();

    const docs = conversationsCollection.docs;

    const convoPromises: Promise<Conversation>[] = docs.map(
      async (el): Promise<Conversation> => {
        const convoOb = await this.conversationService.resolveConversationSnap(
          el
        );
        return convoOb;
      }
    );
    return Promise.all(convoPromises);
  }

  async updateCandidateUser(uid, data) {
    let userDoc = await this.afs.collection('users').doc(uid).get().toPromise();

    return userDoc.ref.update(data);
  }

  async updateCandidateUserProfile(uid, data) {
    let userDoc = await this.afs.collection('users').doc(uid).get().toPromise();

    if (userDoc.exists) {
      const userData: any = userDoc.data();
      const candidateRef = userData.candidate;
      return candidateRef.update(data);
    }
  }

  async getBusinessByBID(bid) {
    let userQuery = await this.afs
      .collection('users', (ref) => {
        return ref.where(
          'business',
          '==',
          this.afs.collection('businesses').doc(bid).ref
        );
      })
      .get()
      .toPromise();

    if (userQuery.empty) return null;
    const userDoc = userQuery.docs[0];
    const userData = userDoc.data();

    if (userData.business) {
      let businessDoc = await (userData.business as DocumentReference).get();

      const business = Business.createBusiness(
        businessDoc.id,
        businessDoc.data()
      );
      business.user = User.createUser(userDoc.id, userData);
      return business;
    } else {
      const business = Business.createBusiness(null, null);
      business.user = User.createUser(userDoc.id, userData);
      return business;
    }
  }

  async getBusinessByUID(uid) {
    let userDoc = await this.afs.collection('users').doc(uid).get().toPromise();

    if (!userDoc.exists) return null;
    const userData = userDoc.data();

    if (userData.business) {
      let businessDoc = await (userData.business as DocumentReference).get();

      const business = Business.createBusiness(
        businessDoc.id,
        businessDoc.data()
      );
      business.user = User.createUser(userDoc.id, userData);
      return business;
    } else {
      const business = Business.createBusiness(null, null);
      business.user = User.createUser(userDoc.id, userData);
      return business;
    }
  }

  async getCandidateByCID(cid) {
    let userQuery = await this.afs
      .collection('users', (ref) => {
        return ref.where(
          'candidate',
          '==',
          this.afs.collection('candidates').doc(cid).ref
        );
      })
      .get()
      .toPromise();

    if (userQuery.empty) return null;
    const userDoc = userQuery.docs[0];
    const userData = userDoc.data();

    if (userData.candidate) {
      let candidateDoc = await (userData.candidate as DocumentReference).get();

      const candidate = Candidate.createCandidate(
        candidateDoc.id,
        candidateDoc.data()
      );
      candidate.user = User.createUser(userDoc.id, userData);
      return candidate;
    } else {
      const candidate = Candidate.createCandidate(null, null);
      candidate.user = User.createUser(userDoc.id, userData);
      return candidate;
    }
  }

  async getCandidateByUID(uid) {
    let userDoc = await this.afs.collection('users').doc(uid).get().toPromise();
    if (!userDoc.exists) return null;
    const userData = userDoc.data();

    if (userData.candidate) {
      let candidateDoc = await (userData.candidate as DocumentReference).get();

      const candidate = Candidate.createCandidate(
        candidateDoc.id,
        candidateDoc.data()
      );
      candidate.user = User.createUser(userDoc.id, userData);
      return candidate;
    } else {
      const candidate = Candidate.createCandidate(null, null);
      candidate.user = User.createUser(userDoc.id, userData);
      return candidate;
    }
  }

  async deleteUser(uid) {
    const callable = this.aff.httpsCallable('deleteUser');

    return callable({ uid: uid }).toPromise();
  }

  async getCandidateCount() {
    const usersCollection = this.afs.collection('users', (ref) => {
      let query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;

      return query.where('type', '==', 'candidate');
    });

    return (await usersCollection.get().toPromise()).docs.length;
  }

  async getBusinessesCount() {
    const usersCollection = this.afs.collection('users', (ref) => {
      let query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;

      return query.where('type', '==', 'business');
    });

    return (await usersCollection.get().toPromise()).docs.length;
  }

  async getPendingBusinessVerificationCount() {
    const usersCollection = this.afs.collection('users', (ref) => {
      let query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;

      return query
        .where('type', '==', 'business')
        .where('verified', '!=', true);
    });

    return (await usersCollection.get().toPromise()).docs.length;
  }

  async getPendingCandidateVerificationCount() {
    const usersCollection = this.afs.collection('conversations', (ref) => {
      let query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;

      return query
        .where('type', '==', ConversationType.VERIFICATION_CHAT)
        .where('status', '==', 0);
    });

    return (await usersCollection.get().toPromise()).docs.length;
  }

  async getBusinesses(limit = 100) {
    const callable = this.aff.httpsCallable('getAdminBusinesses');

    return callable({}).toPromise();
  }

  async getCandidates(limit = 100) {
    const callable = this.aff.httpsCallable('getAdminCandidates');

    return callable({}).toPromise();
  }

  async getAdmins(limit = 100) {
    const userDoc = await this.afs.collection('adminusers').get().toPromise();

    return userDoc.docs.map((adminUserDoc) => {
      const adminUser = adminUserDoc.data();
      adminUser.id = adminUserDoc.id;
      return adminUser;
    });
  }

  async verifyBusiness(uid: string) {
    const userDoc = await this.afs
      .collection('users')
      .doc(uid)
      .get()
      .toPromise();
    if (!userDoc.exists) {
      return false;
    }

    await userDoc.ref.update({ verified: true });
    return true;
  }

  async verifyCandidate(candidateID: string) {
    try {
      const userDoc = await this.afs
        .collection('users', (ref) => {
          let query:
            | firebase.default.firestore.CollectionReference
            | firebase.default.firestore.Query = ref;

          return query.where(
            'candidate',
            '==',
            this.afs.collection('candidates').doc(candidateID).ref
          );
        })
        .get()
        .toPromise();
      if (userDoc.empty) {
        return false;
      }
      const doc = userDoc.docs[0];
      const ttVerification = doc.data().ttVerification;

      if (ttVerification) {
        ttVerification.status = TTVerificationStatus.TTVERIFICATION_VERIFIED;
        await doc.ref.update({
          verified: true,
          ttVerification: ttVerification,
        });
      } else {
        await doc.ref.update({
          verified: true,
          ttVerification: {
            status: TTVerificationStatus.TTVERIFICATION_VERIFIED,
          },
        });
      }

      return true;
    } catch (e) {}
  }

  async getUserByCandidateID(candidateID: string) {
    try {
      const userDoc = await this.afs
        .collection('users', (ref) => {
          let query:
            | firebase.default.firestore.CollectionReference
            | firebase.default.firestore.Query = ref;

          return query.where(
            'candidate',
            '==',
            this.afs.collection('candidates').doc(candidateID).ref
          );
        })
        .get()
        .toPromise();
      if (userDoc.empty) {
        return null;
      }
      const doc = userDoc.docs[0];

      return User.createUser(doc.id, doc.data());
    } catch (e) {
      return null;
    }
  }

  getAdminUser(userID: string) {
    let usersCollection = this.afs.collection('adminusers');
    return usersCollection
      .doc(userID)
      .get()
      .pipe(
        map((docSnap) => {
          if (!docSnap.exists) {
            return null;
          }
          const userData = docSnap.data();

          const user = new AdminUser();
          user.id = docSnap.id;
          user.firstname = userData.firstname;
          user.lastname = userData.lastname;

          return user;
        })
      );
  }

  updateBusinessSubscription(userId: string, subscription: Subscription) {
    const users = this.afs.collection('users');
    return users
      .doc(userId)
      .get()
      .pipe(
        map((docSnap) => {
          if (docSnap.exists) {
            return of(
              docSnap.ref.update({
                subscription: subscription.toJson(),
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }

  async getInvitations() {
    const conversationsCollection = this.afs.collection('invitations');

    const query = await conversationsCollection.get().toPromise();
    if (query.empty) {
      return [];
    }

    const invitationData = query.docs;

    const invitatations = invitationData.map(async (el) => {
      const data = el.data();
      let invitation = new JobInvitation();
      invitation.id = el.id;
      invitation.status = data.status;

      invitation.date = (data.date as firebase.default.firestore.Timestamp).toDate();
      invitation.candidate = null;
      invitation.business = null;
      let businessRef = data.business as DocumentReference;
      let candidateRef = data.candidate as DocumentReference;

      const businessQuery = await businessRef.get();
      if (businessQuery.exists) {
        invitation.business = Business.createBusiness(
          businessQuery.id,
          businessQuery.data()
        );
      }

      const candidateQuery = await candidateRef.get();
      if (candidateQuery.exists) {
        invitation.candidate = Candidate.createCandidate(
          candidateQuery.id,
          candidateQuery.data()
        );
      }
      if (invitation.candidate && invitation.business) {
        return invitation;
      } else {
        return null;
      }
    });

    return (await Promise.all(invitatations)).filter((inv) => inv != null);
  }
}
