export class JobRole {
  name: string;
  id: number;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
    };
  }

  // static JOB_ROLES = [
  // { id: 870, name: ".Net Developer" },
  // { id: 871, name: "3D Modeler" },
  // { id: 872, name: "AI Architect" },
  // { id: 873, name: "AI/Data Science Engineer" },
  // { id: 874, name: "AI Specialist" },
  // { id: 875, name: "Animation technical director" },
  // { id: 876, name: "App designer" },
  // { id: 877, name: "App Developer" },
  // { id: 878, name: "Artificial intelligence engineer" },
  // { id: 879, name: "Automation Engineer" },
  // { id: 880, name: "Backend Developer" },
  // { id: 881, name: "Backend Engineer" },
  // { id: 882, name: "Bio Informatic Software Engineer" },
  // { id: 883, name: "CIO" },
  // { id: 884, name: "CISCO CCAR" },
  // { id: 885, name: "CISCO CCIE" },
  // { id: 886, name: "CISCO CCNA" },
  // { id: 887, name: "CISCO CCNP" },
  // { id: 888, name: "Computer games designer" },
  // { id: 889, name: "Computer Hardware Engineer" },
  // { id: 890, name: "CTO" },
  // { id: 891, name: "Cyber Intelligence officer" },
  // { id: 892, name: "Cyber security developer" },
  // { id: 893, name: "Cyber security Professional" },
  // { id: 894, name: "Cyber security Strategist" },
  // { id: 895, name: "Data Analyst" },
  // { id: 896, name: "Data Architect" },
  // { id: 897, name: "database administrator" },
  // { id: 898, name: "Data Manager" },
  // { id: 899, name: "Data Science Director/Head Of" },
  // { id: 900, name: "Data Science Manager" },
  // { id: 901, name: "Data Scientist" },
  // { id: 902, name: "Data Scientist/Science consultant" },
  // { id: 903, name: "Delivery Team Specialist" },
  // { id: 904, name: "Digital Content Creator" },
  // { id: 905, name: "Digital delivery manager" },
  // { id: 906, name: "Digital Designer" },
  // { id: 907, name: "Digital Product Owner" },
  // { id: 908, name: "Digital product owner" },
  // { id: 909, name: "Digital Transformation Lead" },
  // { id: 910, name: "E learning developer" },
  // { id: 911, name: "Ethical Hacker" },
  // { id: 912, name: "Forensic computer analyst" },
  // { id: 913, name: "Front End Software Engineer" },
  // { id: 914, name: "Full Stack .Net Developer" },
  // { id: 915, name: "Full stack Developer" },
  // { id: 916, name: "Games Developer" },
  // { id: 917, name: "Games Tester" },
  // { id: 918, name: "Geophysical Software Developer" },
  // { id: 919, name: "GO spacial technician" },
  // { id: 920, name: "Graphics Designer" },
  // { id: 921, name: "Head Of Client Delivery" },
  // { id: 922, name: "Head of Computer Services" },
  // { id: 923, name: "Head of Design" },
  // { id: 924, name: "Head of Design Technology" },
  // { id: 925, name: "Head of IT" },
  // { id: 926, name: "Head of IT" },
  // { id: 927, name: "Helpdesk advisor" },
  // { id: 928, name: "Information Scientist" },
  // { id: 929, name: "Information Security Analyst" },
  // { id: 930, name: "Infrastructure Software Developer" },
  // { id: 931, name: "Insights Analyst" },
  // { id: 932, name: "IT consultant" },
  // { id: 933, name: "IT Director" },
  // { id: 934, name: "IT Engineer" },
  // { id: 935, name: "IT Field Engineer" },
  // { id: 936, name: "IT Helpdesk Technician" },
  // { id: 937, name: "IT Manager" },
  // { id: 938, name: "IT Programme Manager" },
  // { id: 939, name: "IT Project Lead" },
  // { id: 940, name: "IT Project Manager" },
  // { id: 941, name: "IT Security Coordinator" },
  // { id: 942, name: "IT service engineer" },
  // { id: 943, name: "IT Service technician" },
  // { id: 944, name: "IT Support Engineer" },
  // { id: 945, name: "IT Systems Architect" },
  // { id: 946, name: "IT Team Lead" },
  // { id: 947, name: "IT Trainer" },
  // { id: 948, name: "Java Developer" },
  // { id: 949, name: "Java Developer AI" },
  // { id: 950, name: "Lead AI Engineer" },
  // { id: 951, name: "Lead Developer Software Engineer" },
  // { id: 952, name: "Lead Researcher Cyber Security" },
  // { id: 953, name: "Lead Test Analyst" },
  // { id: 954, name: "Machine Learning Engineer" },
  // { id: 955, name: "Mobile App Developer" },
  // { id: 956, name: "Network Engineer" },
  // { id: 957, name: "OSL Software developer" },
  // { id: 958, name: "PHP Developer" },
  // { id: 959, name: "Platform Software Engineer" },
  // { id: 960, name: "Principle Data Scientist" },
  // { id: 961, name: "Principle Python Developer" },
  // { id: 962, name: "Product owner" },
  // { id: 963, name: "Programme Delivery Manager" },
  // { id: 964, name: "Programmer" },
  // { id: 965, name: "Python Software Engineer" },
  // { id: 966, name: "QA" },
  // { id: 967, name: "QA Engineering Lead" },
  // { id: 968, name: "QA tester" },
  // { id: 969, name: "Research Scientist" },
  // { id: 970, name: "Robotics Engineer" },
  // { id: 971, name: "Scrum Master" },
  // { id: 972, name: "Senior Cloud Engineer" },
  // { id: 973, name: "Senior Cyber Architect" },
  // { id: 974, name: "Senior Front End Software Engineer" },
  // { id: 975, name: "senior product owner" },
  // { id: 976, name: "Senior Programme Director" },
  // { id: 977, name: "Service Design Manager" },
  // { id: 978, name: "Service Desk Technician" },
  // { id: 979, name: "Social Media Manager" },
  // { id: 980, name: "Solution architect" },
  // { id: 981, name: "Systems Engineer" },
  // { id: 982, name: "Technical Architect" },
  // { id: 983, name: "Technical Director" },
  // { id: 984, name: "Technical Lead" },
  // { id: 985, name: "Technical Officer" },
  // { id: 986, name: "Technical Writer" },
  // { id: 987, name: "Test Engineer" },
  // { id: 988, name: "Test Lead" },
  // { id: 989, name: "Test Manager" },
  // { id: 990, name: "User Researcher" },
  // { id: 991, name: "UX Designer" },
  // { id: 992, name: "Web Content Editor" },
  // { id: 993, name: "Web Content Manager" },
  // { id: 994, name: "Web Designer" },
  // { id: 995, name: "Web developer" },
  // ];
}
