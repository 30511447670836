import { isDate } from "util";
import { AngularFireFunctions } from "@angular/fire/functions";
import { Qualification } from "./../../tt-models/qualification.model";
import { JobInvitation } from "./../../tt-models/job-invitation.model";
import { UserService } from "../user/user.service";
import { Observable, of, empty, combineLatest, from, throwError } from "rxjs";
import { Injectable } from "@angular/core";

import { Candidate } from "src/app/tt-models/candidate.model";
import {
  AngularFirestore,
  DocumentReference,
  CollectionReference,
} from "@angular/fire/firestore";
import { map, takeLast, flatMap, switchMap, catchError } from "rxjs/operators";
import { Skill } from "src/app/tt-models/skill.model";
import { User } from "src/app/tt-models/user.model";
import csc from "country-state-city";
@Injectable({
  providedIn: "root",
})
export class CandidatesService {
  hiddenCandidates = [
    "tZmYKI4KF8eW84cZhVsK",
    "4av5JXWERqYWVuTPYcKq",
    "lS0Qg8FEU3kHMGnWhu4H",
    "BbgQunMWVd1QpCDnQVex",
    "bjgmaSTaG8tuDzlx7Svq",
    "UMj8hrArE9uo1sDJ7dMN",
    "OHrik8HlyAkvJ44cxkA4",
  ];
  constructor(
    private af: AngularFirestore,
    private aff: AngularFireFunctions
  ) {}

  updateProfile(candidateId: string, data: any) {
    let candidatesCollection = this.af.collection("candidates");
    return from(candidatesCollection.doc(candidateId).update(data));
  }

  setAvailable(candidateId: string, available: boolean) {
    let candidatesCollection = this.af.collection("candidates");
    return from(candidatesCollection.doc(candidateId).update({ available }));
  }

  getCandidate(candidateID: string) {
    let candidatesCollection = this.af.collection("candidates");
    return candidatesCollection
      .doc(candidateID)
      .get()
      .pipe(
        map((docSnap) => {
          const userData = docSnap.data();

          return Candidate.createCandidate(candidateID, userData);
        })
      );
  }

  async findSuggestedCandidates(businessId: String, limit = 5) {
    const businessCollection = this.af.collection("businesses");
    const businessRef = businessCollection.doc(businessId.toString()).ref;
    const candidateCollection = this.af.collection("candidates");

    const candidates = await this.searchCandidates({}, limit);

    return Promise.all(
      candidates.map(async (candidateData) => {
        const candidate = Candidate.createCandidate(
          candidateData.id,
          candidateData.data
        );
        const candidateRef = candidateCollection.doc(candidateData.id).ref;

        //Check if has invitation
        const invitationsQuery = await this.af
          .collection("invitations", (ref) => {
            return ref
              .where("candidate", "==", candidateRef)
              .where("business", "==", businessRef);
          })
          .get()
          .toPromise();

        if (!invitationsQuery.empty) {
          return candidate;
        } else {
          return null;
        }
      })
    );
  }

  async searchCandidatesWithInvitationStatus(
    query: any,
    businessId: String,
    limit = 10
  ) {
    const businessCollection = this.af.collection("businesses");
    const businessRef = businessCollection.doc(businessId.toString()).ref;
    const candidateCollection = this.af.collection("candidates");

    const candidates = await this.searchCandidates(query, limit);

    return Promise.all(
      candidates.map(async (candidateData) => {
        const candidate = Candidate.createCandidate(
          candidateData.id,
          candidateData
        );
        const candidateRef = candidateCollection.doc(candidate.id.toString())
          .ref;

        //Check if has invitation
        const invitationsQuery = await this.af
          .collection("invitations", (ref) => {
            return ref
              .where("candidate", "==", candidateRef)
              .where("business", "==", businessRef);
          })
          .get()
          .toPromise();

        if (!invitationsQuery.empty) {
          const invitationData = invitationsQuery.docs[0].data();
          candidate.invitationId = invitationsQuery.docs[0].id;
          candidate.invitationStatus = invitationData.status;
          candidate.invitationDate = (invitationData.date as firebase.default.firestore.Timestamp).toDate();
        }

        return candidate;
      })
    );
  }

  async searchCandidatess(_query: any = {}, limit = 10) {
    const searchCandidatesCallable = this.aff.httpsCallable<
      any,
      [{ id: any; data: any }]
    >("findCandidates");
    return searchCandidatesCallable({ query: _query, limit }).toPromise();
  }

  matchFilter = (value: any, filter) => {
    if (isDate(filter.data)) {
      var t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(value.seconds || 0);
      value = t;
    }
    switch (filter.op) {
      case "in-array":
        if (!Array.isArray(value)) {
          value = [value];
        }
        console.log(value);
        const filterData = filter.data as [];

        return (value as []).some((r) => {
          let isInArray = false;
          for (var i = 0; i <= filterData.length; i++) {
            const el = (filterData[i] + "").trim().toLowerCase();
            const propVal = (r + "").trim().toLowerCase();

            if (propVal.includes(el) || el.includes(propVal)) {
              isInArray = true;
            }
            // } else if (typeof filterData[i] == "number") {
            //   const el = filterData[i] as number;
            //   if (el == r) {
            //     isInArray = true;
            //   }
            // }
          }

          return isInArray;
        });
        break;

      case ">=":
        return value >= filter.data;
        break;

      case "<=":
        return value <= filter.data;
        break;

      case "==":
        return value == filter.data;
        break;

      case "between":
        return value >= filter.data[0] && value <= filter.data[1];
        break;
    }
  };

  processFilter = (data: any, filter: any) => {
    let matched = true;
    let prop = data[filter.label];

    const dotPos = filter.label.indexOf(".");
    if (dotPos > -1) {
      prop = data[filter.label.substr(0, dotPos)];
      if (prop) {
        prop = prop[filter.label.substr(dotPos + 1)];
      } else {
        return false;
      }
    }

    const hashPos = filter.label.indexOf("#");
    if (hashPos > -1) {
      prop = data[filter.label.substr(0, hashPos)];

      if (prop) {
        const subPropName = filter.label.substr(hashPos + 1);

        if (Array.isArray(prop)) {
          prop = prop.map((p) => p[subPropName]);
        }
      }
    }

    if (filter.label == "jobLocationCities#name") {
      prop.push(data.city);
    }

    if (prop) {
      matched = this.matchFilter(prop, filter);
    }

    const hasSub = (filter.sub || null) != null;

    if (hasSub) {
      const subOp = filter.sub.op;
      switch (subOp) {
        case "or":
          matched = matched || this.processFilter(data, filter.sub.filter);
          break;
      }
    }

    return matched;
  };

  matchesFilters = (data: any, filters, op = "and") => {
    if (op == "and") {
      let shouldContinue = true;

      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];

        shouldContinue = this.processFilter(data, filter);

        if (!shouldContinue) {
          return { matched: false };
        }
      }
    } else if (op == "or") {
      let numMatches = 0;
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];

        if (this.processFilter(data, filter)) {
          numMatches++;
        }

        console.log(numMatches);
      }

      return { matched: numMatches > 0, numMatches };
    }

    return { matched: true };
  };

  async searchCandidates(_query: any = {}, limit = 10) {
    var hasUsedInArray = false;
    var leftInArrayFilters: {
      label: string;
      op: string;
      data: any;
      sub?: any;
      or?: any;
    }[] = [];

    const candidatesCollection = this.af.collection("candidates", (ref) => {
      let query:
        | firebase.default.firestore.CollectionReference
        | firebase.default.firestore.Query = ref;

      query = query.where("available", "==", true);

      if (_query.annualSalary) {
        if (isNaN(_query.annualSalary.from)) {
          _query.annualSalary.from = 0;
        }
        if (isNaN(_query.annualSalary.to)) {
          _query.annualSalary.to = 1000000;
        }
        leftInArrayFilters.push({
          label: "annualSalary",
          op: "between",
          data: [_query.annualSalary.from, _query.annualSalary.to],
        });
      }

      if (_query.dailyRate) {
        if (isNaN(_query.dailyRate.from)) {
          _query.dailyRate.from = 0;
        }
        if (isNaN(_query.dailyRate.to)) {
          _query.dailyRate.to = 1000000;
        }

        leftInArrayFilters.push({
          label: "desiredRate",
          op: "between",
          data: [_query.dailyRate.from, _query.dailyRate.to],
          sub: {
            op: "or",
            filter: {
              label: "minimumRate",
              op: "between",
              data: [_query.dailyRate.from, _query.dailyRate.to],
            },
          },
        });
      }

      if (_query.skills && _query.skills.length > 0) {
        // hasUsedInArray = true;
        // query = query.where("skills", "array-contains-any", _query.skills);

        leftInArrayFilters.push({
          label: "skills#id",
          op: "in-array",
          data: _query.skills.map((q) => q.id),
        });
      }

      if (_query.qualifications && _query.qualifications.length > 0) {
        leftInArrayFilters.push({
          label: "qualifications#id",
          op: "in-array",
          data: _query.qualifications.map((q) => q.id),
        });
      }

      if (_query.job_types && _query.job_types.length > 0) {
        leftInArrayFilters.push({
          label: "jobType.id",
          op: "in-array",
          data: _query.job_types.map((q) => q.id),
        });
      }

      if (_query.industries && _query.industries.length > 0) {
        leftInArrayFilters.push({
          label: "industry.id",
          op: "in-array",
          data: _query.industries.map((q) => q.id),
        });
      }

      if (_query.roles && _query.roles.length > 0) {
        leftInArrayFilters.push({
          label: "jobRole.id",
          op: "in-array",
          data: _query.roles.map((role) => role.id),
        });
      }

      if (_query.jobSearchAreas && _query.jobSearchAreas.length > 0) {
        let sub = null;
        if (
          _query.jobSearchAreas.indexOf("city") > -1 &&
          _query.cities.length > 0
        ) {
          sub = {
            op: "or",
            filter: {
              label: "jobLocationCities#name",
              op: "in-array",
              data: _query.cities.map((ci) => {
                return ci.name;
              }),
            },
          };

          // leftInArrayFilters.push();
          (_query.jobSearchAreas as Array<any>).splice(
            _query.jobSearchAreas.indexOf("city"),
            1
          );
        }

        if (_query.jobSearchAreas.length > 0) {
          leftInArrayFilters.push({
            label: "jobSearchAreas",
            op: "in-array",
            data: _query.jobSearchAreas,
            sub: sub,
          });
        } else {
          leftInArrayFilters.push(sub.filter);
        }
      }

      if (_query.availability && _query.availability.length > 0) {
        if (_query.availability.indexOf("immediately") > -1) {
          _query.availability.push("within_month");
          _query.availability.push("within_week");
        }

        leftInArrayFilters.push({
          label: "availability",
          op: "in-array",
          data: _query.availability,
        });

        if (_query.availability.indexOf("from_date") > -1) {
          leftInArrayFilters.push({
            label: "jobStarting",
            op: ">=",
            data: new Date(_query.availabiltiy_start_date),
          });
        }
      }
      return query.orderBy("verified", "desc").limit(limit);
    });

    const candidatesQuery = await candidatesCollection.get().toPromise();

    if (candidatesQuery.empty) {
      return [];
    }

    //More filters?
    let filteredCandidaates = candidatesQuery.docs
      .map((doc) => {
        const data = doc.data();
        return { id: doc.id, ...data } as any;
      })
      .filter((candidate) => {
        return this.hiddenCandidates.indexOf(candidate.id.toString()) == -1;
      });

    if (leftInArrayFilters.length > 0) {
      filteredCandidaates = filteredCandidaates.filter((candidate) => {
        const filterResult = this.matchesFilters(
          candidate,
          leftInArrayFilters,
          "or"
        );
        candidate.searchRating = filterResult.numMatches;
        return filterResult.matched;
      });
    }
    return filteredCandidaates.sort((a, b) => {
      if (a.verified === b.verified) {
        return b.searchRating - a.searchRating;
      } else if (a.verified) return -1;
      else return 1;
    });
  }
}
