
export class AdminUser {
  id: String;
  email: String;
  firstname: String;
  lastname: String;

  constructor() {
    this.id = "";
    this.email = "";
    this.firstname = "";
    this.lastname = "";
  }


  get fullname () {
      return this.firstname + " " + this.lastname;
  }

}
