import { TagInputComponent } from 'src/app/components/tag-input/tag-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { PipesModule } from './pipes/pipes.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxStripeModule } from 'ngx-stripe';
import { CandidatesPageComponent } from './pages/candidates-page/candidates-page.component';
import { ManageCandidatePageComponent } from './pages/manage-candidate-page/manage-candidate-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { BusinessesPageComponent } from './pages/businesses-page/businesses-page.component';
import { REGION } from '@angular/fire/functions';
import { ManageBusinessPageComponent } from './pages/manage-business-page/manage-business-page.component';
import { UpdateSubscriptionModalComponent } from './components/update-subscription-modal/update-subscription-modal.component';
import { CandidateProfileModalComponent } from './components/candidate-profile-modal/candidate-profile-modal.component';
import { ConnectionsPageComponent } from './pages/connections-page/connections-page.component';
import { BusinessProfileModalComponent } from './components/business-profile-modal/business-profile-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { SendCandidatesPushNotificationModalComponent } from './components/send-candidates-push-notification-modal/send-candidates-push-notification-modal.component';
const providers = [{ provide: REGION, useValue: 'europe-west1' }];

import { QuillModule } from 'ngx-quill';
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,

    SettingsPageComponent,
  ],
  exports: [ReactiveFormsModule],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SweetAlert2Module.forRoot(),
    NgbModule,

    NgxStripeModule.forRoot(
      'pk_test_51H7FmGAfC0eF3EyvZSKurZGhtIb92qY1MkuXLoBf1sNd23DnJMBwTktasoxMb6GybPzhgrDMg1GZXczS4vy3nSKc00zO1QeXXo'
    ),

    ClipboardModule,
  ],
  providers: [...providers],
  bootstrap: [AppComponent],
})
export class AppModule {}
